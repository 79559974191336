import { location } from './window';

const toURL = path =>
  `${location.protocol()}//${location.host()}${path}`;

export const get = (path, { headers = {}, ...opts } = {}) =>
  fetch(toURL(path), {
    method: 'GET',
    headers,
    ...opts,
  });

export const post = (path, { data = {}, headers = {} } = {}) =>
  fetch(toURL(path), {
    method: 'POST',
    headers,
    body: data,
  });

export const patch = (path, { data = {}, headers = {} } = {}) =>
  fetch(toURL(path), {
    method: 'PATCH',
    headers,
    body: data,
  });

export const del = (path, { headers = {} } = {}) =>
  fetch(toURL(path), {
    method: 'DELETE',
    headers,
  });

// export const http = { get, post, patch, delete: del };

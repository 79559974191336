const host = () => window.location.host;
const path = () => window.location.pathname;
const protocol = () => window.location.protocol;
const url = () => `${protocol()}//${host()}${path()}`

export const location = {
  host,
  path,
  protocol,
  url,
};

export const pushUrl = toURL => window.location.assign(toURL);

import $ from 'jquery';
window.$ = $;
window.jQuery = $;

import 'select2';
import 'select2/dist/css/select2.css';
import '@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.css';

import * as dom from './dom';

const DEFAULT_SELECT2_OPTIONS = {
  theme: "bootstrap4",
  allowClear: true,
  closeOnSelect: false,
};

export const select2 = (id, options = {}) =>
  dom.on.ready(() => {
    $(`#${id}`).select2({
      ...DEFAULT_SELECT2_OPTIONS,
      ...options,
    });
  });

/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import $ from 'jquery';
window.$ = $;
window.jQuery = $;

import Popper from 'popper.js';
window.Popper = Popper;

import Plotly from 'plotly.js-dist';
window.Plotly = Plotly;

require('bootstrap');

import 'trix';
import 'trix/dist/trix.css';

import '@rails/activestorage';
import '@rails/actiontext';

import '../styles/application.scss';

import * as dom from '../utils/dom';
window.$dom = dom;

import * as http from '../utils/http';
window.$http = http;

import { select2 } from '../utils/select2';
window.select2 = select2;

import { pushUrl } from '../utils/window';

import 'jquery'
import {} from 'jquery-ujs';

const createLinkDataMethodForm = link => {
  const action = dom.el.attr.get(link, 'href');
  const method = dom.el.data.get(link, 'method').toUpperCase();

  const [{ content: csrfToken }] = dom.el.find('[name="csrf-token"]');

  const form = dom.el.create('form', { method: 'POST', action }, `
    <input type="hidden" name="_method" value="${method}">
    <input type="hidden" name="csrf_token" value="${csrfToken}">
  `);

  const [body] = dom.el.find('body');
  if (body && form) body.appendChild(form);

  return form;
};

const handleLinkDataMethod = dom.on.click(e => {
  const { target: link } = e;
  const form = createLinkDataMethodForm(link);

  form.submit();
  e.preventDefault();
});

$(document).ready(() => $('[data-toggle="tooltip"]').tooltip());

dom.on.ready(() => {
  // dom.el.find('a[data-method]').forEach(handleLinkDataMethod);


  const actionTextAttachments = dom.el.find('action-text-attachment');

  const openAttachment = e => {
    e.preventDefault();
    const { target: { parentElement: { parentElement: { parentElement: el } } } } = e;
    const url = dom.el.attr.get(el, 'url')
    console.log(el);
    console.log(url);

    const link = document.createElement('a')
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  actionTextAttachments.forEach(el => {
    el.addEventListener('click', openAttachment, { capture: true });
  });
});
